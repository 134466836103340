<template>
    <div class="container">
        <Y-header></Y-header>

        <div class="body-container">
            <div class="blocker"></div>
            <banner :data="bannerData" height="600px" :speed="bannerSpeed"></banner>
            <center-title text="客户的信任和案例" bold font-size="42" position="center"></center-title>
            <center-title text="来自各地客户的信任，已服务超过100+的付费企业" bold font-size="20" color="#808080" position="center"></center-title>

            <div class="list-container">
<!--                <h2 class="title">智慧平台</h2>-->
<!--                <div class="tabs-button">-->
<!--                    <span class="button" v-for="(item,i) in tabsButton" :class="[i==active?'buttonActive':'']" :key="i" @click="tabsClick(item,i)">{{item}}</span>-->
<!--                </div>-->
                <div class="list">
                    <div class="list-item" v-for="(item,i) in listData" :key="i" @click="itemClick(item)">
                        <div class="item-title">
                            <div class="title-box">
                                <span class="title-text">{{item.name}}</span>
                                <span class="icon">HOT</span>
                            </div>
                            <img class="arrow" src="@/assets/箭头备份@2x.png"/>
                        </div>
                        <div class="introduce">
                            <p>{{item.introduce}}</p>
                        </div>
                    </div>
                </div>
            </div>
<!--            <div class="list-container" style="margin-top: 80px;padding-bottom: 80px">-->
<!--                <h2 class="title">全链物联网</h2>-->
<!--                <div class="tabs-button">-->
<!--                    <span class="button" v-for="(item,i) in tabsButton" :class="[i==active?'buttonActive':'']" :key="i" @click="tabsClick(item,i)">{{item}}</span>-->
<!--                </div>-->
<!--                <div class="list">-->
<!--                    <div class="list-item" v-for="(item,i) in listData" :key="i" @click="itemClick(item)">-->
<!--                        <div class="item-title">-->
<!--                            <div class="title-box">-->
<!--                                <span class="title-text">{{item.title}}</span>-->
<!--                                <span class="icon">HOT</span>-->
<!--                            </div>-->
<!--                            <img class="arrow" src="@/assets/箭头备份@2x.png"/>-->
<!--                        </div>-->
<!--                        <div>-->
<!--                            <p>{{item.apply}}</p>-->
<!--                        </div>-->
<!--                    </div>-->
<!--                </div>-->
<!--            </div>-->
            <a-pagination  :show-total="total => `共 ${total} 条`" style="text-align: center;padding: 20px" @change="pageChange" v-model="current" :total="total" show-less-items />
        </div>
        <Y-footer></Y-footer>
        <a-back-top/>
    </div>
</template>

<script>
import Banner from "@/components/banner.vue";
import footer from "@/components/footer.vue";
import header from "@/components/header.vue";
import CenterTitle from "@/components/centerTitle.vue";
import handleRequest from "@/utils/handleRequest";
import {bannerData, projectCase} from "@/api/apis";
import tools from "@/utils/tools";
export default {
    name: "case",
    components: {
        CenterTitle,
        Banner,
        'Y-footer':footer,
        'Y-header':header,
    },
    data() {
        return {
            current:1,
            total:0,
            bannerData:[],
            bannerSpeed:2000,
            listData:[],
            contentListData:[],
            active:0,
            tabsButton:['农业种植','畜牧养殖','销售平台','制造平台'],
        }
    },
    mounted() {
        this.getBannerData();
        this.getListData();
    },
    methods: {
        async getBannerData(){
            const data = handleRequest(await bannerData({type:'项目案例',state:1}))
            let banner = data.content
            banner.forEach(item=>{
                item.img = JSON.parse(item.img)[0]
            })
            this.bannerSpeed = (banner[0]?.second*1000) || this.bannerSpeed
            this.bannerData = banner

        },
        tabsClick(item,i){
            console.log(i)
            this.active = i
        },
        async getListData(){
            let params = {
                page:this.current,
                pageSize:9,
                state:1
            }
            const data = handleRequest(await projectCase(params))
            this.total = data.totalElements
            this.current = data.number+1
            console.log(data)
            let ListData = data.content
            this.contentListData = ListData
            ListData.forEach(item=>{
                item.hardware = JSON.parse(item.hardware)
                item.software = JSON.parse(item.software)
                item.introduce = tools.clearRichText(item.introduce)
            })
            ListData = JSON.stringify(ListData)
            ListData = JSON.parse(ListData)
            this.listData = ListData
        },
        pageChange(page,pageSize){
            this.current = page
            this.getListData()
        },
        itemClick(item){
            this.$router.push({
                path:'/caseDetail',
                query:{id:item.id}
            })
        },
    },
}
</script>

<style scoped lang="scss">
@media screen and (max-width: 1920px){
    .list-container{
        margin: 0 170px !important;

    }
}
.container{
    .body-container{
        background-color: #FFFFFF;

        .blocker{
            height: 92px;
        }
        .list-container{
            margin: 0 280px;
            padding: 40px 0;
            .title{
                font-size: 24px;
                font-family: PingFangSC, PingFang SC;
                font-weight: 500;
                color: #3769FE;
                line-height: 24px;
                margin-left: 22px;
            }
            .tabs-button{
                padding: 20px 0;
                margin-left: 22px;
                .buttonActive{
                    background-color: #3769FE !important;
                    color: #FFFFFF !important;
                }
                .button{
                    cursor: pointer;
                    user-select: none;
                    color: #333333;
                    margin-right: 10px;
                    font-size: 16px;
                    font-family: PingFangSC, PingFang SC;
                    font-weight: 500;
                    padding: 10px;
                    border-radius: 8px;
                    background-color: #F1F8FF;
                }
            }

            .list{
                display: flex;
                flex-wrap: wrap;
                align-items: center;
                .list-item{
                    margin: 22px;
                    padding: 29px;
                    border-radius: 12px;
                    background-color:#F1F8FF;
                    width: 30%;
                    height: 210px;
                    cursor: pointer;
                    .item-title{
                        display: flex;
                        justify-content: space-between;
                        align-items: center;
                        margin-bottom: 10px;
                        .title-box{
                            display: flex;
                            align-items: center;
                            .title-text{
                                max-width: 220px;
                                white-space: nowrap;
                                text-overflow: ellipsis;
                                overflow: hidden;
                                font-size: 20px;
                                font-family: PingFangSC, PingFang SC;
                                font-weight: 500;
                                color: #333333;
                                margin-right: 5px;
                            }
                            .icon{
                                color: #FFFFFF;
                                background: linear-gradient(90deg, #EB2201 0%, #EA5E21 99%);
                                border-radius: 4px;
                                padding: 3px;
                            }
                        }
                        .arrow{
                            width: 26px;
                        }
                    }
                    .introduce{
                        height: 130px;
                        display: -webkit-box;
                        -webkit-box-orient: vertical;
                        -webkit-line-clamp: 6;
                        text-overflow: ellipsis;
                        overflow: hidden;
                    }
                }
            }
        }
    }
}

</style>
